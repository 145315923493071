import React from "react";
import "./nav.css"

export default function InfoBar() {
	return (
		<div className="info-wrap">
			<div className="info-inner">
				<div className="info-left">
					<a
						href="https://www.google.com/maps/place/Rendbe-Rak+Kft./@47.4666432,19.3054971,17z/data=!3m1!4b1!4m6!3m5!1s0x4741c0d05a661533:0xa4761269d31bd580!8m2!3d47.4666396!4d19.308072!16s%2Fg%2F11fn6skf5h?entry=ttu"
						target="_blank"
						rel="noreferrer"
					>
						<i className="fa-solid fa-location-dot title-icon"></i>
						<span className="info-link">1171 Bp. Fehértemplom utca 8.</span>
					</a>
					<a href="mailto:f.ferenc@rendberak.hu">
						<i className="fa-solid fa-envelope title-icon"></i>
						<span className="info-link">f.marta@rendberak.hu</span>
					</a>
					<div>
						<i className="fa-solid fa-phone title-icon"></i>
						<span className="info-text">+36 30 560 3157</span>
					</div>
				</div>
				<div className="info-social">
					<span className="info-social-text">Kövess minket: </span>
					<a href="https://fb.com/rendberak" target="_blank" rel="noreferrer">
						<i className="fa-brands fa-square-facebook fa-xl"></i>
					</a>
				</div>
			</div>
		</div>
	)
};
